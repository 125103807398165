import React from 'react'

import Layout from '../../components/LayoutENG'
import PostRoll from '../../components/PostRoll'

export default class BlogIndexPageENG extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/_MG_5062.webp')`
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 black, -0.5rem 0 0 black",
              backgroundColor: "black",
              color: "white",
              padding: "1rem"
            }}
          >
            Tutorials
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <PostRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
